import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import OtpInput from 'react-otp-input';  // Correct import
import { Container, Form, Button } from 'react-bootstrap';
import forgotPasswordImg from "../assets/images/OTPVerification.png"

export default function OTPScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const [otp, setOtp] = useState('');
  const [timer, setTimer] = useState(600); // 10 minutes in seconds
  const email = location.state?.email || '';

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const handleVerifyOTP = async () => {
    if (otp.length === 6) {
      try {
        const response = await fetch(`https://services.allhomedesire.com/all/validate-otp?email=${email}&otp=${otp}`, {
          method: 'POST',
        });
        const data = await response.json();
        if (response.ok && data.statusType === 'SUCCESS') {
          navigate('/reset-password', { state: { email } });
        } else {
          alert('Invalid OTP. Please try again.');
        }
      } catch (error) {
        alert('Error validating OTP: ' + error.message);
      }
    } else {
      alert('Please enter a valid 6-digit OTP');
    }
  };

  const handleResendOTP = async () => {
    if (timer === 0) {
      setTimer(600);
      try {
        const response = await fetch(`https://services.allhomedesire.com/all/forgot-password?email=${email}`, {
          method: 'POST',
        });
        const data = await response.json();
        if (response.ok && data.statusType === 'SUCCESS') {
          alert('OTP resent to your email');
        } else {
          alert('Failed to resend OTP. Please try again.');
        }
      } catch (error) {
        alert('Error resending OTP: ' + error.message);
      }
    } else {
      alert('Please wait before requesting a new OTP');
    }
  };

  return (
    <Container className="mt-5"style={{ maxWidth: "400px",maxHeight:"800px" }}>
          <div className="text-center mb-4">
        <img 
          src={forgotPasswordImg} 
          alt="Forgot Password" 
          style={{ width: "100%", maxWidth: "200px"}} 
        />
      </div>
      <h2>Enter OTP ?</h2>
      <p className="text-left" style={{ color: "black", fontSize: "12px" }}>A 6-digit code has been sent to {email}.</p>
      <Form className="d-flex flex-column align-items-center">
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          inputStyle={{
            width: '3rem',
            height: '3rem',
            margin: '0.5rem',
            fontSize: '1.5rem',
            textAlign: 'center',
            border: '1px solid #000',
            borderRadius: '5px',
          }}
          renderInput={(props) => <input {...props} />} // Fix for renderInput error
        />
        <Button className="mt-3 w-100" onClick={handleVerifyOTP} yy>
          Submit
        </Button>
      </Form>
     
    <div className="text-center d-flex justify-content-between align-items-center">
  <p className="mb-0">
    OTP Valid for <span className="text-primary">{formatTime(timer)}</span>
  </p>
  <Button
    variant="link"
    onClick={handleResendOTP}
    disabled={timer > 0}
  >
    Resend OTP
  </Button>
</div>


    </Container>
  );
}
