import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Logo from "../../assets/images/logo.png";
import { useSelector, useDispatch } from "react-redux";
import classes from "./MainNavigation.module.css";
import NewsletterSignup from "../newsletter/NewsletterSignup";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../features/auth/authActions";
import { FaBell } from "react-icons/fa";
import Styles from "./MainNavigation.module.css";
import Api from "../../admin/components/api/Api";
import secureLocalStorage from "react-secure-storage";
import { setFormData, resetStep } from "../../features/formData/formSlice";
import UserProfile from "../../assets/images/user-profile.svg";

function MainNavigation() {
  const loggedInUser = useSelector((state) => state.auth.user);
  const userType = loggedInUser?.userDetails?.userType;
  const roles = loggedInUser?.userDetails?.roles;
  const [userDetail, setUserDetails] = useState(null);
  const [userName, setUserName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [notificationCount, setNotificationCount] = useState(null);
  const [navbarExpanded, setNavbarExpanded] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const id = loggedInUser?.userDetails?.id;

  const getDetailsApi = Api({
    url: `admin/user/id/${id}`,
  });

  const getNotificationApi = Api({
    url: `notify/getCount`,
  });

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        setIsLoading(true);
        try {
          const responseData = await getDetailsApi.fetchData();
          setUserDetails(responseData?.data?.user);
          setUserName(responseData?.data?.user?.first_name);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const getNotificationData = async () => {
      try {
        const responseData = await getNotificationApi.fetchDataBySingleId();
        if (responseData?.data?.statusType === "SUCCESS") {
          setNotificationCount(responseData.data.totalRecord);
        }
      } catch (error) {
        console.error("Error fetching notification count:", error);
      }
    };

    getNotificationData();
  }, []);

  const logoutHandler = async () => {
    try {
      console.log("hello world kaise ho");
      const formData = {}; // Resetting form data

      dispatch(resetStep());
      dispatch(setFormData(formData)); // Dispatching action to set form data

      dispatch(logout());

      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  };

  const navLinks = [
    {
      to: "/dashboard",
      label: "Dashboard",
      allowedUserTypes: [
        "SUPER_ADMIN",
        "ALL_HOME_DESIRE",
        "PARTNER",
        "CUSTOMER",
      ],
      role: [],
    },
    {
      to: "/post",
      label: "Posts",
      allowedUserTypes: [
        "SUPER_ADMIN",
        "ALL_HOME_DESIRE",
        // "PARTNER",
        // "CUSTOMER",
      ],
      role: [],
    },
    {
      to: "/property",
      label: "Properties",
      allowedUserTypes: [
        "SUPER_ADMIN",
        "ALL_HOME_DESIRE",
        // "PARTNER",
        // "CUSTOMER",
      ],
      role: [],
    },
    // {
    //   to: `/contact-us`,
    //   label: "Leads",
    //   allowedUserTypes: ["SUPER_ADMIN", "ALL_HOME_DESIRE"],
    //   role: [],
    // },
    {
      to: `/query`,
      label: "Queries",
      allowedUserTypes: ["SUPER_ADMIN", "ALL_HOME_DESIRE"],
      role: [],
    },
    {
      to: `/user`,
      label: "Masters",
      allowedUserTypes: ["SUPER_ADMIN", "ALL_HOME_DESIRE", "PARTNER"],
      role: ["ROLE_ADMIN"],
    },
    {
      to: `my-preference`,
      label: "Preference",
      allowedUserTypes: [
        "SUPER_ADMIN",
        "ALL_HOME_DESIRE",
        "PARTNER",
        "CUSTOMER",
      ],
      role: [],
    },
    {
      to: "/user/profile",
      label: "Profile",
      allowedUserTypes: [
        "SUPER_ADMIN",
        "ALL_HOME_DESIRE",
        "PARTNER",
        "CUSTOMER",
      ],
      role: [],
    },
  ];

  const handleSelect = () => {
    setDropdownOpen(false);
  };

  const handleLinkClick = () => {
    setDropdownOpen(false);
  };

  const handleToggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleNavClick = () => {
    setNavbarExpanded(false);
    setDropdownOpen(false);
  };

  return (
    <header
      className="main-header bg-light sticky-top"
      style={{ postion: "relative", zIndex: "9999999" }}
    >
      <div className="header-sticky ">
        <Navbar
          collapseOnSelect
          expand="lg"
          className=""
          expanded={navbarExpanded}
          onToggle={() => setNavbarExpanded(!navbarExpanded)}
        >
          <Container>
            <Navbar.Brand>
              <NavLink to="/">
                <img src={Logo} alt="Logo" style={{ marginLeft: " -10px" }} />
              </NavLink>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="main-nav-menu">
                <ul className="navbar-nav mr-auto">
                  <li className="nav-item">
                    <NavLink
                      to="/"
                      className="nav-link"
                      onClick={handleNavClick}
                    >
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/about-us"
                      className="nav-link"
                      onClick={handleNavClick}
                    >
                      About us
                    </NavLink>
                  </li>
                
                  <li className="nav-item">
                    <NavLink
                      to="/property-list"
                      className="nav-link"
                      onClick={handleNavClick}
                    >
                      Property
                    </NavLink>
                  </li>
                  <li className="nav-item submenu">
                    <NavDropdown title="Services" id="collapsible-nav-dropdown">
                      <ul>
                        <li className="nav-item">
                          <NavLink
                            className="nav-link"
                            to="/interiordesign"
                            onClick={handleNavClick}
                          >
                            Interior Design
                          </NavLink>
                        </li>
                      </ul>
                    </NavDropdown>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="contactus"
                      className="nav-link"
                      onClick={handleNavClick}
                    >
                      Contact us
                    </NavLink>
                  </li>
                  {loggedInUser?.statusType === "SUCCESS" ? (
                    <>
                      <li className="nav-item submenu user-image">
                        <NavDropdown
                          title={
                            <>
                              {userDetail?.photo ? (
                                <img
                                  src={`https://images.allhomedesire.in/${userDetail?.photo}`}
                                  alt="User"
                                  className="rounded-circle"
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    marginRight: "5px",
                                    padding: "2px",
                                    objectFit: "cover",
                                    display: "inline-block",
                                  }}
                                />
                              ) : (
                                <img
                                  src={UserProfile}
                                  alt="User"
                                  className="rounded-circle user-profile-img"
                                />
                              )}
                              <span>{userName ? userName : "Profile"}</span>
                            </>
                          }
                          id="collapsible-nav-dropdown"
                          onSelect={handleSelect}
                          // show={dropdownOpen}
                          // onMouseEnter={() => setDropdownOpen(true)}
                          // onMouseLeave={() => setDropdownOpen(false)}
                        >
                          <ul>
                            {navLinks.map((link, index) =>
                              (link.allowedUserTypes.includes(userType) ||
                                (userType === "PARTNER" &&
                                  roles.includes("ROLE_ADMIN") &&
                                  link.allowedUserTypes.includes("PARTNER"))) &&
                              (userType !== "PARTNER" ||
                                link.role.length === 0 ||
                                roles.includes("ROLE_ADMIN")) ? (
                                <li className="nav-item" key={index}>
                                  <Link
                                    className="nav-link"
                                    to={link.to}
                                    onClick={handleNavClick}
                                  >
                                    {link.label}
                                  </Link>
                                </li>
                              ) : null
                            )}
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                onClick={() => {
                                  logoutHandler();
                                  handleNavClick();
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Logout
                              </a>
                            </li>
                          </ul>
                        </NavDropdown>
                      </li>
                      <li className="nav-item">
                        <Link to={"/notification"}>
                          <FaBell size={24} />
                          {notificationCount > 0 && (
                            <span className={`${Styles.notificationCount}`}>
                              {notificationCount}
                            </span>
                          )}
                        </Link>
                      </li>
                    </>
                  ) : (
                    <>
                      <li className="nav-item">
                        <NavLink to="/login" className="nav-link login-text">
                          <i className="fa fa-user-o" aria-hidden="true"></i>
                          Login
                        </NavLink>
                      </li>
                    </>
                  )}
                </ul>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </header>
  );
}

export default MainNavigation;
