import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Button, Row, Col, Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import PropertyDetail from "./PropertyDetail"; 

const PropertyFilter = ({ onClose }) => {
  const [wishTo, setWishTo] = useState("");
  const [state, setState] = useState("");
  const [statesList, setStatesList] = useState([]);
  const [city, setCity] = useState("");
  const [citiesList, setCitiesList] = useState([]);
  const [area, setArea] = useState("");
  const [areasList, setAreasList] = useState([]);
  const [propertyType, setPropertyType] = useState("");
  const [selectedPropertyType, setSelectedPropertyType] = useState("");
  const [propertySubtypes, setPropertySubtypes] = useState([]);
  const [propertySubtype, setPropertySubtype] = useState("");
  const [loading, setLoading] = useState(false);
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [properties, setProperties] = useState([]); // New state to store property data
  const loggedInUser = useSelector((state) => state.auth.user);
  const token = loggedInUser?.token;
  const [selectedProperty, setSelectedProperty] = useState(null);

  useEffect(() => {
    fetchStates();
    fetchPropertyTypes();
    console.log("State Changed:", state);
  }, [state]);

  const fetchStates = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "https://services.allhomedesire.com/admin/states",
        { page_number: 1, page_size: 20, order_by: "ASC" },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setStatesList(response.data?.states || []);
    } catch (error) {
      console.error("Error fetching states:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCities = async (stateId) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `https://services.allhomedesire.com/admin/cities/${stateId}`,
        { page_number: 1, page_size: 20, order_by: "ASC" },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCitiesList(response.data?.cities || []);
    } catch (error) {
      console.error("Error fetching cities:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAreas = async (cityId) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `https://services.allhomedesire.com/admin/areas/${cityId}`,
        { page_number: 1, page_size: 20, order_by: "ASC" },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAreasList(response.data?.areas || []);
    } catch (error) {
      console.error("Error fetching areas:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPropertyTypes = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://services.allhomedesire.com/all/property/types"
      );
      setPropertyTypes(response.data.propertyTypes || []);
    } catch (error) {
      console.error("Error fetching property types:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPropertySubtypes = async (typeId) => {
    if (!typeId) return;
    setLoading(true);
    try {
      const response = await axios.get(
        `https://services.allhomedesire.com/all/property/sub/types/${typeId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setPropertySubtypes(response.data.propertySubTypes || []);
    } catch (error) {
      console.error("Error fetching property subtypes:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePropertyTypeChange = (e) => {
    const selectedTypeId = e.target.value;
    setSelectedPropertyType(selectedTypeId);
    setPropertySubtypes([]);
    fetchPropertySubtypes(selectedTypeId);
  };

  const handleStateChange = (e) => {
    const selectedStateId = e.target.value;
    setState(selectedStateId);
    setCity("");
    setArea("");
    setCitiesList([]);
    setAreasList([]);
    if (selectedStateId) fetchCities(selectedStateId);
    console.log("Selected State ID:", selectedStateId); // Debugging Output
  };

  const handleCityChange = (e) => {
    const selectedCityId = e.target.value;
    setCity(selectedCityId);
    setArea("");
    setAreasList([]);
    if (selectedCityId) fetchAreas(selectedCityId);
  };

  const handlePropertyClick = (property) => {
    setSelectedProperty(property); // Set the selected property
  };

  const handleBackToList = () => {
    setSelectedProperty(null); // Go back to the property list
  };

const handleSubmit = async (e) => {
  e.preventDefault();
  setLoading(true);
  
  console.log("Current State Value Before API Call:", state); // Debugging Output
  
  let selectedStateName = "";
  if (state) {
    const selectedState = statesList.find((s) => s.id.toString() === state.toString());
    selectedStateName = selectedState ? selectedState.name : "";
  }

  let selectedCityName = "";
  if (city) {
    const selectedCity = citiesList.find((c) => c.id.toString() === city.toString());
    selectedCityName = selectedCity ? selectedCity.name : "";
  }

  let selectedAreaName = "";
  if (area) {
    const selectedArea = areasList.find((a) => a.id.toString() === area.toString());
    selectedAreaName = selectedArea ? selectedArea.name : "";
  }


  let selectedPropertyTypeName = "";
  if (selectedPropertyType) {
    const selectedType = propertyTypes.find((t) => t.id.toString() === selectedPropertyType.toString());
    selectedPropertyTypeName = selectedType ? selectedType.name : "";
  }

  let selectedPropertySubtype = "";
if (propertySubtype) {
  const selectedSubtype = propertySubtypes.find((sub) => sub.id.toString() === propertySubtype.toString());
  selectedPropertySubtype = selectedSubtype ? selectedSubtype.name : "";
}

 
  console.log("Mapped State Name:", selectedStateName); // Debugging Output
  console.log("Mapped City Name:", selectedCityName); // Debugging Output
  console.log("Mapped Area Name:", selectedCityName); // Debugging Output
  console.log("Mapped type Name:", selectedPropertyTypeName); // Debugging Output
  console.log("Mapped subtype Name:", selectedPropertySubtype ); // Debugging Output

  
  const filterData = {
    purpose: wishTo || "",
    state: selectedStateName, // Sending the state name
    city: selectedCityName || "", // Sending the city name, not ID
    area: selectedAreaName || "", // Correct this to send the area value
    type: selectedPropertyTypeName || "", // Correct this to send the property type
    keyboard_search: selectedPropertySubtype  || "", // You can update this if it's supposed to be something else
    page_number: 1,
    page_size: 20,
    order_by: "ASC",
  };

  console.log("API Request Data:", filterData); // Debugging Output

  try {
    const response = await axios.post(
      "https://services.allhomedesire.com/property/list/preferred/v1",
      filterData,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    console.log("API Response:", response.data);
    setProperties(response.data.properties || []);
  } catch (error) {
    console.error("Error fetching properties:", error);
  } finally {
    setLoading(false);
  }
};

  
  

  return (
    <div className="p-3 border rounded bg-white">
       {selectedProperty ? (
      <PropertyDetail property={selectedProperty} onBack={handleBackToList} />
    ) : (
      <>
      <Form onSubmit={handleSubmit}>
        {/* Filters Section */}
        <Row>
          <Col>
            <Form.Group>
              <div>
                <Form.Check
                  inline
                  label="Buy"
                  type="radio"
                  name="wishTo"
                  value="buy"
                  checked={wishTo === "buy"}
                  onChange={(e) => setWishTo(e.target.value)}
                />
                <Form.Check
                  inline
                  label="Sell"
                  type="radio"
                  name="wishTo"
                  value="sell"
                  checked={wishTo === "sell"}
                  onChange={(e) => setWishTo(e.target.value)}
                />
                <Form.Check
                  inline
                  label="Rent"
                  type="radio"
                  name="wishTo"
                  value="rent"
                  checked={wishTo === "rent"}
                  onChange={(e) => setWishTo(e.target.value)}
                />
              </div>
            </Form.Group>
          </Col>
        </Row>

        {/* State, City, Area */}
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>State</Form.Label>
              <Form.Control
                as="select"
                value={state}
                onChange={handleStateChange}
              >
                <option value="">Select State</option>
                {statesList.map((s) => (
                  <option key={s.id} value={s.id}>
                    {s.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>City</Form.Label>
              <Form.Control
                as="select"
                value={city}
                onChange={handleCityChange}
                disabled={!state}
              >
                <option value="">Select City</option>
                {citiesList.map((c) => (
                  <option key={c.id} value={c.id}>
                    {c.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Area</Form.Label>
              <Form.Control
                as="select"
                value={area}
                onChange={(e) => setArea(e.target.value)}
                disabled={!city}
              >
                <option value="">Select Area</option>
                {areasList.map((a) => (
                  <option key={a.id} value={a.id}>
                    {a.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        {/* Property Type and Subtype */}
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Property Type</Form.Label>
              <Form.Control
                as="select"
                value={selectedPropertyType}
                onChange={handlePropertyTypeChange}
                disabled={loading}
              >
                <option value="">
                  {loading ? "Loading..." : "Select Property Type"}
                </option>
                {propertyTypes.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Property Subtype</Form.Label>
              <Form.Control as="select" disabled={!selectedPropertyType}>
                <option value="">Select Property Subtype</option>
                {propertySubtypes.map((subtype) => (
                  <option key={subtype.id} value={subtype.id}>
                    {subtype.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Button variant="primary" type="submit" className="mt-3">
          Apply Filter
        </Button>
        <Button variant="secondary" onClick={onClose} className="mt-3 ms-2">
          Close
        </Button>
      </Form>

      {properties.length > 0 ? (
        <div className="mt-4">
          <h3>Properties Found</h3>
          <Row>
            {properties.map((property) => (
              <Col key={property.id} sm={12} md={6} lg={4}>
                {/* <Card className="border rounded-3"> */}
                <Card
                    className="border rounded-3"
                    onClick={() => handlePropertyClick(property)}
                    style={{ cursor: "pointer" }}
                  >
                  
                  <Card.Img
                    variant="top"
                    src={`${property.assetUrl}/${property.images[0].image_url}`}
                  />
                  <Card.Body>
                    <Card.Text className="text-primary fw-bold fs-5" >₹{property.budget}</Card.Text>
                    <Card.Title>
                      <strong>{property.title}</strong>
                    </Card.Title>

                    <Card.Text style={{ fontSize: "0.75rem" }}>
                      {property.area?.name}, {property.city?.name},{" "}
                      {property.state?.name}
                    </Card.Text>

                    <Card.Text style={{ fontSize: "0.75rem" }}>
                      {property.description}
                    </Card.Text>

                    <div className="mb-3">
                      <span
                        className="badge text-dark mr-2"
                        style={{
                          backgroundColor: "orange",
                          padding: "0.5rem 1rem", // Adjust padding as needed
                        }}
                      >
                        {property.purpose?.name}
                      </span>
                      <span
                        className="badge text-light mr-2"
                        style={{
                          backgroundColor: "blue",
                          padding: "0.5rem 1rem", // Adjust padding as needed
                        }}
                      >
                        {property.type?.name}
                      </span>
                      <span
                        className="badge text-light mr-2"
                        style={{
                          backgroundColor: "green",
                          padding: "0.5rem 0.5rem", // Adjust padding as needed
                        }}
                      >
                        {property.title}
                      </span>
                    </div>

                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      ) : (
        <div>No properties found</div>
      )}
       </>
          )}
    </div>
  );
};

export default PropertyFilter;