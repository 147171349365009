// import React, { useState, useEffect } from "react";
// import { Col, Container, Row } from "react-bootstrap";
// import { login } from "../features/auth/authActions";
// import { useDispatch, useSelector } from "react-redux";
// import { Link, useNavigate, Navigate } from "react-router-dom";
// import Spinner from "../components/common/spinner/Spinner";
// import Error from "../components/common/error/Error";
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import * as Yup from "yup";
// import Notification, {showToast,} from "../components/common/notification/Notification";
// import { setCredentials } from "../features/auth/authSlice";

// const Login = () => {
//   const navigate = useNavigate();
//   const [isLoading, setIsLoading] = useState(false);
//   const [isError, setIsError] = useState(false);
//   const [formData, setFormData] = useState({
//     user_name: "",
//     password: "",
//     rememberMe: false,
//   });

//   const initialValues = {
//     user_name: "",
//     password: "",
//     rememberMe: false,
//   };

//   const dispatch = useDispatch();

//   const isAuthenticated = useSelector((state) => state.auth);

//   useEffect(() => {
//     console.log(isAuthenticated);
//     if (isAuthenticated.user !== null) {
//       if (isAuthenticated?.user?.statusType === "SUCCESS") {
//         navigate("/dashboard");
//       }
//     }
//   }, [isAuthenticated, navigate]);
//   const handleSubmit = async (values, { setSubmitting }) => {
//     try {
//       setIsLoading(true);
//       const baseUrl = process.env.REACT_APP_BASE_URL;

//       setSubmitting(true);

//       const checks = await dispatch(login(values));

//       if (checks?.data?.statusType === "SUCCESS") {
//         navigate("/dashboard");
//       } else {
//         throw new Error("Login failed");
//       }
//     } catch (error) {
//       setIsError(true);
//       console.error("Error:", error);
//     } finally {
//       setSubmitting(false);
//       setIsLoading(false);
//     }
//   };

//   const handleChange = (name, value) => {
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleCheckboxChange = (event) => {
//     const { name, checked } = event.target;
//     handleChange(name, checked);
//   };

//   return (
//     <>
//       <Container>
//         {isLoading ? (
//           <Spinner loading={true} />
//         ) : (
//           <Row className="justify-content-center mt-5 mb-5">
//             <Col md={6}>
//               <div className="card">
//                 <div className="card-header">
//                   <h3>Login</h3>
//                 </div>
//                 <div className="card-body">
//                   <Formik
//                     initialValues={initialValues}
//                     validate={(values) => {
//                       const errors = {};
//                       if (!values.user_name) {
//                         errors.user_name = "Required";
//                       }
//                       if (!values.password) {
//                         errors.password = "Required";
//                       }
//                       return errors;
//                     }}
//                     onSubmit={handleSubmit}
//                   >
//                     {({ isSubmitting }) => (
//                       <Form>
//                         <div className="mb-3">
//                           <label htmlFor="email" className="form-label">
//                             Email address
//                           </label>
//                           <Field
//                             type="email"
//                             className="form-control"
//                             id="email"
//                             aria-describedby="emailHelp"
//                             placeholder="Enter email"
//                             name="user_name"
//                           />
//                           <ErrorMessage
//                             name="user_name"
//                             component="div"
//                             className="text-danger"
//                           />
//                         </div>
//                         <div className="mb-3">
//                           <label htmlFor="password" className="form-label">
//                             Password
//                           </label>
//                           <Field
//                             type="password"
//                             className="form-control"
//                             id="password"
//                             name="password"
//                             placeholder="Password"
//                           />
//                           <ErrorMessage
//                             name="password"
//                             component="div"
//                             className="text-danger"
//                           />
//                         </div>
//                         <div className="form-check mb-3">
//                           <Field
//                             type="checkbox"
//                             className="form-check-input"
//                             id="rememberMe"
//                             name="rememberMe"
//                           />
//                           <label
//                             className="form-check-label"
//                             htmlFor="rememberMe"
//                           >
//                             Remember me
//                           </label>
//                         </div>
//                         {isError && (
//                           <Error
//                             message="Incorrect Credentials"
//                             variant="danger"
//                             show={true}
//                           />
//                         )}

//                         <button
//                           type="submit"
//                           className="btn btn-primary"
//                           disabled={isSubmitting}
//                                                 >
//                           {isSubmitting ? <Spinner loading={true} /> : "Login"}
//                         </button>
//                       </Form>
//                     )}
//                   </Formik>

//                   <Row>
//                     <p
//                       style={{
//                         display: "flex",
//                         alignItems: "center",
//                         justifyContent: "center",
//                         marginTop: "1rem",
//                       }}
//                     >
//                       Didn't Have An Account ?
//                       <Link
//                         style={{
//                           marginLeft: "1rem",
//                           color: "#ED7D3A",
//                         }}
//                         to={"/sign-up"}
//                       >
//                         SignUp
//                       </Link>
//                     </p>
//                     <Col></Col>
//                   </Row>
//                 </div>
//               </div>
//             </Col>
//           </Row>
//         )}
//       </Container>
//     </>
//   );
// };

// export default Login;

//Above code is correct and below code is for testing password visibility toggle and remember me
import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { login } from "../features/auth/authActions";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "../components/common/spinner/Spinner";
import Error from "../components/common/error/Error";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { setCredentials } from "../features/auth/authSlice";
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Add icons for eye toggle

const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [formData, setFormData] = useState({
    user_name: "",
    password: "",
    rememberMe: false,
  });
  const [passwordVisible, setPasswordVisible] = useState(false); // State to toggle password visibility

  const initialValues = {
    user_name: "",
    password: "",
    rememberMe: false,
  };

  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth);

  // Check local storage for saved credentials when the component mounts
  useEffect(() => {
    const savedUserName = localStorage.getItem("user_name");
    const savedPassword = localStorage.getItem("password");
    const savedRememberMe = localStorage.getItem("rememberMe");

    if (savedRememberMe === "true") {
      setFormData({
        user_name: savedUserName,
        password: savedPassword,
        rememberMe: true,
      });
    }

    if (isAuthenticated.user !== null) {
      if (isAuthenticated?.user?.statusType === "SUCCESS") {
        navigate("/dashboard");
      }
    }
  }, [isAuthenticated, navigate]);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setIsLoading(true);
      setSubmitting(true);

      const checks = await dispatch(login(values));

      if (checks?.data?.statusType === "SUCCESS") {
        navigate("/dashboard");

        // If Remember Me is checked, save credentials to local storage
        if (values.rememberMe) {
          localStorage.setItem("user_name", values.user_name);
          localStorage.setItem("password", values.password);
          localStorage.setItem("rememberMe", "true");
        } else {
          localStorage.removeItem("user_name");
          localStorage.removeItem("password");
          localStorage.setItem("rememberMe", "false");
        }
      } else {
        throw new Error("Login failed");
      }
    } catch (error) {
      setIsError(true);
      console.error("Error:", error);
    } finally {
      setSubmitting(false);
      setIsLoading(false);
    }
  };

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    handleChange(name, checked);
  };

  return (
    <>
      <Container>
        {isLoading ? (
          <Spinner loading={true} />
        ) : (
          <Row className="justify-content-center mt-5 mb-5">
            <Col md={6}>
              <div className="card">
                <div className="card-header">
                  <h3>Login</h3>
                </div>
                <div className="card-body">
                  <Formik
                    initialValues={initialValues}
                    validate={(values) => {
                      const errors = {};
                      if (!values.user_name) {
                        errors.user_name = "Required";
                      }
                      if (!values.password) {
                        errors.password = "Required";
                      }
                      return errors;
                    }}
                    onSubmit={handleSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <div className="mb-3">
                          <label htmlFor="email" className="form-label">
                            Email address
                          </label>
                          <Field
                            type="email"
                            className="form-control"
                            id="email"
                            aria-describedby="emailHelp"
                            placeholder="Enter email"
                            name="user_name"
                          />
                          <ErrorMessage
                            name="user_name"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="password" className="form-label">
                            Password
                          </label>
                          <div className="position-relative">
                            <Field
                              type={passwordVisible ? "text" : "password"}
                              className="form-control"
                              id="password"
                              name="password"
                              placeholder="Password"
                            />
                            <span
                              className="position-absolute"
                              style={{ top: "50%", right: "10px", transform: "translateY(-50%)" }}
                              onClick={() => setPasswordVisible(!passwordVisible)}
                            >
                              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                            </span>
                          </div>
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="form-check mb-3">
                          <Field
                            type="checkbox"
                            className="form-check-input"
                            id="rememberMe"
                            name="rememberMe"
                            checked={formData.rememberMe}
                            onChange={handleCheckboxChange}
                          />
                          <label className="form-check-label" htmlFor="rememberMe">
                            Remember me
                          </label>
                        </div>
                        {isError && (
                          <Error
                            message="Incorrect Credentials"
                            variant="danger"
                            show={true}
                          />
                        )}

                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? <Spinner loading={true} /> : "Login"}
                        </button>

                        <div className="mt-3">
                          <Link to="/Email-Screen" className="text-primary">
                            Forgot the Password
                          </Link>
                        </div>
                      </Form>
                    )}
                  </Formik>

                  <Row>
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "1rem",
                      }}
                    >
                      Didn't Have An Account ?
                      <Link
                        style={{
                          marginLeft: "1rem",
                          color: "#ED7D3A",
                        }}
                        to={"/sign-up"}
                      >
                        SignUp
                      </Link>
                    </p>
                    <Col></Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default Login;
