import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/Custom.css";
import ErrorPage from "./pages/Error";
import HomePage from "./pages/Home";
import RootLayout from "./pages/Root";
import Footer from "./components/common/Footer";
import About from "./pages/About";
import Login from "./pages/Login";
import PropertyListingForm from "./admin/dashboard/property/PropertyList";
import SignUp from "./pages/SignUp";
import AddProperty from "./admin/dashboard/property/AddProperty";
import Protected from "./components/auth/Protected";
import User from "./admin/dashboard/user/User";
import Query from "./admin/dashboard/query/Query";
import Post from "./admin/dashboard/post/Post";
import ContactUs from "./admin/dashboard/contactUs/ContactUs";
import UserItem from "./admin/dashboard/user/UserItem";
import UserRoot from "./admin/dashboard/user/UserRoot";
import QueryItem from "./admin/dashboard/query/QueryItem";
import QueryRoot from "./admin/dashboard/query/QueryRoot";
import ContactUsRoot from "./admin/dashboard/contactUs/ContactUsRoot";
import ContactUsItem from "./admin/dashboard/contactUs/ContactUsItem";
import PostItem from "./admin/dashboard/post/PostItem";
import PostRoot from "./admin/dashboard/post/PostRoot";
import PropertyRoot from "./admin/dashboard/property/PropertyRoot";
import PropertyItem from "./admin/dashboard/property/PropertyItem";
import PropertyListing from "./pages/PropertyListing";
import PropertyDetails from "./components/propertyDetails/PropertyDetails";
import AddUser from "./admin/dashboard/user/AddUser";
import EditUser from "./admin/dashboard/user/EditUser";
import EditQuery from "./admin/dashboard/query/EditQuery";
import EditPost from "./admin/dashboard/post/EditPost";
import EditProperty from "./admin/dashboard/property/EditProperty";
import ConatactUsFront from "./pages/ContactUs";
import { MainPassowd } from "./admin/dashboard/user/ChangePassword";
import EditUserProfile from "./admin/dashboard/userProfile/EditUserProfile";
import PropertiesByCity from "./components/propertyByCity/PropertyByCity";
import InteriorDesign from "./pages/InteriorDesign";
import ContactRoot from "./vendor/common/contact/ContactRoot";
import AddContact from "./vendor/common/contact/AddContact";
import VendorContactUs from "./vendor/common/contact/ContactUs";
import VendorContactUsItem from "./vendor/common/contact/ContactUsItem";
import EditContactUs from "./vendor/common/contact/EditContactUs";
import CityRoot from "./admin/dashboard/city/CityRoot";
import City from "./admin/dashboard/city/City";
import AddCity from "./admin/dashboard/city/AddCity";
import CityItem from "./admin/dashboard/city/CityItem";
import EditCity from "./admin/dashboard/city/EditCity";
import StateRoot from "./admin/dashboard/state/StateRoot";
import State from "./admin/dashboard/state/State";
import AddState from "./admin/dashboard/state/AddState";
import StateItem from "./admin/dashboard/state/StateItem";
import EditState from "./admin/dashboard/state/EditState";
import CountryRoot from "./admin/dashboard/country/CountryRoot";
import Country from "./admin/dashboard/country/Country";
import AddCountry from "./admin/dashboard/country/AddCountry";
import CountryItem from "./admin/dashboard/country/CountryItem";
import EditCountry from "./admin/dashboard/country/EditCountry";
import UserTypeRoot from "./admin/dashboard/userType/UserTypeRoot";
import UserType from "./admin/dashboard/userType/UserType";
import AddUserType from "./admin/dashboard/userType/AddUserType";
import UserTypeItem from "./admin/dashboard/userType/UserTypeItem";
import EditUserType from "./admin/dashboard/userType/EditUserType";
import ServiceRoot from "./admin/dashboard/services/ServiceRoot";
import Service from "./admin/dashboard/services/Service";
import AddService from "./admin/dashboard/services/AddService";
import ServiceItem from "./admin/dashboard/services/ServiceItem";
import EditService from "./admin/dashboard/services/EditService";
import PropertyPurposeRoot from "./admin/dashboard/property-purpose/PropertyPurposeRoot";
import PropertyPurpose from "./admin/dashboard/property-purpose/PropertyPurpose";
import AddPropertyPurpose from "./admin/dashboard/property-purpose/AddPropertyPurpose";
import PropertyPurposeItem from "./admin/dashboard/property-purpose/PropertyPurposeItem";
import EditPropertyPurpose from "./admin/dashboard/property-purpose/EditPropertyPurpose";
import PropertyStatus from "./admin/dashboard/property-status/PropertyStatus";
import PropertyStatusRoot from "./admin/dashboard/property-status/PropertyStatusRoot";
import AddPropertyStatus from "./admin/dashboard/property-status/AddPropertyStatus";
import PropertyStatusItem from "./admin/dashboard/property-status/PropertyStatusItem";
import EditPropertyStatus from "./admin/dashboard/property-status/EditPropertyStatus";
import PropertyTypeRoot from "./admin/dashboard/property-type/PropertyTypeRoot";
import PropertyType from "./admin/dashboard/property-type/PropertyType";
import AddPropertyType from "./admin/dashboard/property-type/AddPropertyType";
import PropertyTypeItem from "./admin/dashboard/property-type/PropertyTypeItem";
import EditPropertyType from "./admin/dashboard/property-type/EditPropertyType";
import AreaRoot from "./admin/dashboard/area/AreaRoot";
import Area from "./admin/dashboard/area/Area";
import AddArea from "./admin/dashboard/area/AddArea";
import AreaItem from "./admin/dashboard/area/AreaItem";
import EditArea from "./admin/dashboard/area/EditArea";
import Preference from "./admin/dashboard/my-preference/MyPreference";
import LeadManagement from "./admin/dashboard/lead-management/LeadManagement";
import LeadManagementRoot from "./admin/dashboard/lead-management/LeadManagementRoot";
import AddLeadManagement from "./admin/dashboard/lead-management/AddLeadManagement";
import LeadManagementItem from "./admin/dashboard/lead-management/LeadManagementItem";
import EditLeadManagement from "./admin/dashboard/lead-management/EditLeadManagement";
import NetworkManagementRoot from "./vendor/common/network-management/NetworkManagementRoot";
import NetworkManagement from "./vendor/common/network-management/NetworkManagement";
import AddNetworkManagement from "./vendor/common/network-management/AddNetworkManagement";
import NetworkManagementItem from "./vendor/common/network-management/NetworkManagementItem";
import EditNetworkManagement from "./vendor/common/network-management/EditNetworkManagement";
import NotificationItem from "./admin/components/notification/NotificationItem";
import Notification from "./admin/components/notification/Notification";
import Profile from "./admin/dashboard/userProfile/Profile";
import PaymentMessage from "./pages/PaymentMessage";
import DashboardRoot from "./admin/dashboard/homepage/DashboardRoot";
import Tab3 from "./admin/dashboard/homepage/Tab3";
import Tab4 from "./admin/dashboard/homepage/Tab4";
import Tab5 from "./admin/dashboard/homepage/Tab5";
import Tab2 from "./admin/dashboard/homepage/Tab2";
import PostBasicPropertyForm from "./admin/dashboard/post/PostBasicPropertyForm";
import PropertySubTypeRoot from "./admin/dashboard/property-subtype/PropertySubTypeRoot";
import PropertySubType from "./admin/dashboard/property-subtype/PropertySubType";
import AddPropertySubType from "./admin/dashboard/property-subtype/AddPropertySubType";
import PropertySubTypeItem from "./admin/dashboard/property-subtype/PropertySubTypeItem";
import EditPropertySubType from "./admin/dashboard/property-subtype/EditPropertySubType";
import Payment from "./pages/Payment";
import MainPartnerRegistration from "./pages/partnerRegistration/MainPartnerRegistration";
import PromotionalProperty from "./admin/dashboard/homepage/PromotionalProperty";
import AdminProtected from "./components/auth/AdminProtected";
import PartnerProtected from "./components/auth/PartnerProtected";
import AdminAndPartnerProtected from "./components/auth/AdminAndPartnerProtected";
import PartnerRoot from "./admin/dashboard/unactivepartner/PartnerRoot";
import Partner from "./admin/dashboard/unactivepartner/Partner";
import PartnerItem from "./admin/dashboard/unactivepartner/PartnerItem";
import EditPartner from "./admin/dashboard/unactivepartner/EditPartner";
import AllHomeDesire from "./admin/dashboard/allhomedesire/AllHomeDesire";
import AllHomeDesireRoot from "./admin/dashboard/allhomedesire/AllHomeDesireRoot";
import AllHomeDesireItem from "./admin/dashboard/allhomedesire/AllHomeDesireItem";
import EditAllHomeDesire from "./admin/dashboard/allhomedesire/EditAllHomeDesire";
import Policy from "./pages/Policy";
import EmailScreen from "./pages/EmailScreen";
import OTPScreen from "./pages/OTPScreen";
import ResetPasswordScreen from "./pages/ResetPasswordScreen";


const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <HomePage /> },
      {
        path: "about-us",
        element: <About />,
      },
      {
        path: "policy",
        element: <Policy />,
      },
      
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },

      {
        path: "Email-Screen",
        element: <EmailScreen />,
      },

      {
        path: "OTP-Screen",
        element: <OTPScreen />,
      },

      {
        path: "reset-password",
        element: <ResetPasswordScreen />,
      },

      {
        path: "property-list",
        element: <PropertyListing />,
      },
      {
        path: "property/city/:cityname",
        element: <PropertiesByCity />,
      },
      {
        path: "contactus",
        element: <ConatactUsFront />,
      },
      {
        path: "partnerreg",
        element: <MainPartnerRegistration />,
      },
      {
        path: "partnerreg/documentupload/:id",
        element: <PaymentMessage />,
      },
      {
        path: "partnerreg/documentupload/payment",
        element: <Payment />,
      },
      {
        path: "dashboard",
        element: (
          <Protected>
            <DashboardRoot />
          </Protected>
        ),
        children: [
          {
            index: true,
                   element: <Tab2 />,
          },
          {
            path: "preferred",
            element: (
              <Protected>
                <Tab2 />
              </Protected>
            ),
          },
          {
            path: "myspace",
            element: (
              <Protected>
                <Tab3 />
              </Protected>
            ),
          },
          {
            path: "addPostDashboard",
            element: (
              <Protected>
                <Tab4 />
              </Protected>
            ),
          },
          {
            path: "promotional",
            element: (
              <Protected>
                <PromotionalProperty />
              </Protected>
            ),
          },
          {
            path: "addPropertyDashboard",
            element: (
              <Protected>
                <Tab5 />
              </Protected>
            ),
          },
        ],
      },
      {
        path: "interiordesign",
        element: <InteriorDesign />,
      },

      {
        path: "/property/add-property",
        element: (
          <Protected>
            <AddProperty />
          </Protected>
        ),
      },

      {
        path: "/user/profile",
        element: (
          <Protected>
            <Profile />
          </Protected>
        ),
      },
      {
        path: "my-preference",
        element: (
          <Protected>
            <Preference />
          </Protected>
        ),
      },
      {
        path: "/user/edit-profile",
        element: (
          <Protected>
            <EditUserProfile />
          </Protected>
        ),
      },
      {
        path: "/property-details/:id",
        element: <PropertyDetails />,
      },
      {
        path: "/notification/:id",
        element: <NotificationItem />,
      },
      {
        path: "/notification",
        element: <Notification />,
      },
      {
        path: "property",
        element: (
          <Protected>
            <PropertyRoot />
          </Protected>
        ),
        children: [
          {
            index: true,
            element: <PropertyListingForm />,
          },
          {
            path: ":propertyId",
            id: "property-details",
            children: [
              {
                index: true,
                element: (
                  <Protected>
                    <PropertyItem />
                  </Protected>
                ),
              },
              {
                path: "edit",
                element: <EditProperty />,
              },
            ],
          },
        ],
      },
      {
        path: "changepassword/:userId",
        element: (
          <Protected>
            <MainPassowd />
          </Protected>
        ),
      },
      {
        path: "user",
        element: (
          <AdminAndPartnerProtected>
            <UserRoot />
          </AdminAndPartnerProtected>
        ),
        children: [
          {
            index: true,
            element: (
              <AdminAndPartnerProtected>
                <User />
              </AdminAndPartnerProtected>
            ),
          },
          {
            path: "add-user",
            element: (
              <AdminAndPartnerProtected>
                <AddUser />
              </AdminAndPartnerProtected>
            ),
          },
          {
            path: ":userId",
            id: "user-details",
            children: [
              {
                index: true,
                element: (
                  <AdminAndPartnerProtected>
                    <UserItem />
                  </AdminAndPartnerProtected>
                ),
              },

              {
                path: "edit",
                element: (
                  <AdminAndPartnerProtected>
                    <EditUser />
                  </AdminAndPartnerProtected>
                ),
              },
              {
                path: "changepassword",
                element: (
                  <Protected>
                    <MainPassowd />
                  </Protected>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "query",
        element: (
          <AdminProtected>
            <QueryRoot />
          </AdminProtected>
        ),
        children: [
          {
            index: true,
            element: (
              <AdminProtected>
                <Query />
              </AdminProtected>
            ),
          },
          {
            path: ":queryId",
            id: "query-details",
            children: [
              {
                index: true,
                element: (
                  <AdminProtected>
                    <QueryItem />
                  </AdminProtected>
                ),
              },
              // {
              //   path: "edit",
              //   element: <EditQuery />,
              // },
            ],
          },
        ],
      },
      {
        path: "contact-us",
        element: (
          <AdminProtected>
            <ContactUsRoot />
          </AdminProtected>
        ),
        children: [
          {
            index: true,
            element: (
              <AdminProtected>
                <ContactUs />
              </AdminProtected>
            ),
          },
          {
            path: ":contactUsId",
            id: "contactus-details",
            children: [
              {
                index: true,
                element: (
                  <AdminProtected>
                    <ContactUsItem />
                  </AdminProtected>
                ),
              },
                      ],
          },
        ],
      },

      {
        path: "partners",
        element: (
          <AdminProtected>
            <PartnerRoot />
          </AdminProtected>
        ),
        children: [
          {
            index: true,
            element: (
              <AdminProtected>
                <Partner />
              </AdminProtected>
            ),
          },
          {
            path: "add-user",
            element: (
              <AdminAndPartnerProtected>
                <AddUser />
              </AdminAndPartnerProtected>
            ),
          },
          {
            path: ":partnerId",
            id: "partner-details",
            children: [
              {
                index: true,
                element: (
                  <AdminProtected>
                    <PartnerItem />
                  </AdminProtected>
                ),
              },
              {
                path: "edit",
                element: (
                  <AdminProtected>
                    <EditPartner />
                  </AdminProtected>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "allhomedesire",
        element: (
          <AdminProtected>
            <AllHomeDesireRoot />
          </AdminProtected>
        ),
        children: [
          {
            index: true,
            element: (
              <AdminProtected>
                <AllHomeDesire />
              </AdminProtected>
            ),
          },
          {
            path: "add-user",
            element: (
              <AdminAndPartnerProtected>
                <AddUser />
              </AdminAndPartnerProtected>
            ),
          },
          {
            path: ":allhomedesireId",
            id: "all-home-desire-details",
            children: [
              {
                index: true,
                element: (
                  <AdminProtected>
                    <AllHomeDesireItem />
                  </AdminProtected>
                ),
              },
              {
                path: "edit",
                element: (
                  <AdminProtected>
                    <EditAllHomeDesire />
                  </AdminProtected>
                ),
              },
            ],
          },
        ],
      },

      {
        path: "post",
        element: (
          <Protected>
            <PostRoot />
          </Protected>
        ),
        children: [
          {
            index: true,
            element: <Post />,
          },
          {
            path: "add-post",
            element: <PostBasicPropertyForm />,
          },
          {
            path: ":postId",
            id: "post-details",
            children: [
              {
                index: true,
                element: (
                  <Protected>
                    <PostItem />
                  </Protected>
                ),
              },
              {
                path: "edit",
                element: <EditPost />,
              },
            ],
          },
        ],
      },
      {
        path: "contact",
        element: (
          <PartnerProtected>
            <ContactRoot />
          </PartnerProtected>
        ),
        children: [
          {
            index: true,
            element: (
              <PartnerProtected>
                <VendorContactUs />
              </PartnerProtected>
            ),
          },
          {
            path: "add-contact",
            element: (
              <PartnerProtected>
                <AddContact />
              </PartnerProtected>
            ),
          },
          {
            path: ":contactId",
            id: "contact-details",
            children: [
              {
                index: true,
                element: (
                  <PartnerProtected>
                    <VendorContactUsItem />
                  </PartnerProtected>
                ),
              },
              {
                path: "edit",
                element: (
                  <PartnerProtected>
                    <EditContactUs />
                  </PartnerProtected>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "user-type",
        element: (
          <AdminProtected>
            <UserTypeRoot />
          </AdminProtected>
        ),
        children: [
          {
            index: true,
            element: (
              <AdminProtected>
                <UserType />
              </AdminProtected>
            ),
          },
          {
            path: "add",
            element: (
              <AdminProtected>
                <AddUserType />
              </AdminProtected>
            ),
          },
          {
            path: ":userTypeId",
            id: "userType-details",
            children: [
              {
                index: true,
                element: (
                  <AdminProtected>
                    <UserTypeItem />
                  </AdminProtected>
                ),
              },
              {
                path: "edit",
                element: (
                  <AdminProtected>
                    <EditUserType />
                  </AdminProtected>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "state",
        element: (
          <AdminProtected>
            <StateRoot />
          </AdminProtected>
        ),
        children: [
          {
            index: true,
            element: (
              <AdminProtected>
                <State />
              </AdminProtected>
            ),
          },
          {
            path: "add",
            element: (
              <AdminProtected>
                <AddState />
              </AdminProtected>
            ),
          },

          {
            path: ":stateId",
            id: "state-details",
            children: [
              {
                index: true,
                element: (
                  <AdminProtected>
                    <StateItem />
                  </AdminProtected>
                ),
              },
              {
                path: "edit",
                element: (
                  <AdminProtected>
                    <EditState />
                  </AdminProtected>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "country",
        element: (
          <AdminProtected>
            <CountryRoot />
          </AdminProtected>
        ),
        children: [
          {
            index: true,
            element: (
              <AdminProtected>
                <Country />
              </AdminProtected>
            ),
          },
          {
            path: "add",
            element: (
              <AdminProtected>
                <AddCountry />
              </AdminProtected>
            ),
          },
          {
            path: ":countryId",
            id: "country-details",
            children: [
              {
                index: true,
                element: (
                  <AdminProtected>
                    <CountryItem />,
                  </AdminProtected>
                ),
              },
              {
                path: "edit",
                element: (
                  <AdminProtected>
                    <EditCountry />
                  </AdminProtected>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "service",
        element: (
          <AdminProtected>
            <ServiceRoot />
          </AdminProtected>
        ),
        children: [
          {
            index: true,
            element: (
              <AdminProtected>
                <Service />
              </AdminProtected>
            ),
          },
          {
            path: "add-service",
            element: (
              <AdminProtected>
                <AddService />
              </AdminProtected>
            ),
          },
          {
            path: ":serviceId",
            id: "service-details",
            children: [
              {
                index: true,
                element: (
                  <AdminProtected>
                    <ServiceItem />
                  </AdminProtected>
                ),
              },
              {
                path: "edit",
                element: (
                  <AdminProtected>
                    <EditService />
                  </AdminProtected>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "property-purpose",
        element: (
          <AdminProtected>
            <PropertyPurposeRoot />
          </AdminProtected>
        ),
        children: [
          {
            index: true,
            element: (
              <AdminProtected>
                <PropertyPurpose />
              </AdminProtected>
            ),
          },
          {
            path: "add-propertyPurpose",
            element: (
              <AdminProtected>
                <AddPropertyPurpose />
              </AdminProtected>
            ),
          },
          {
            path: ":propertyPurposeId",
            id: "propertyPurpose-details",

            children: [
              {
                index: true,
                element: (
                  <AdminProtected>
                    <PropertyPurposeItem />
                  </AdminProtected>
                ),
              },
              {
                path: "edit",
                element: (
                  <AdminProtected>
                    <EditPropertyPurpose />
                  </AdminProtected>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "property-status",
        element: (
          <AdminProtected>
            <PropertyStatusRoot />
          </AdminProtected>
        ),
        children: [
          {
            index: true,
            element: (
              <AdminProtected>
                <PropertyStatus />
              </AdminProtected>
            ),
          },
          {
            path: "add-propertyStatus",
            element: (
              <AdminProtected>
                <AddPropertyStatus />
              </AdminProtected>
            ),
          },
          {
            path: ":propertyStatusId",
            id: "propertyStatus-details",

            children: [
              {
                index: true,
                element: (
                  <AdminProtected>
                    <PropertyStatusItem />
                  </AdminProtected>
                ),
              },
              {
                path: "edit",
                element: (
                  <AdminProtected>
                    <EditPropertyStatus />
                  </AdminProtected>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "property-type",
        element: (
          <AdminProtected>
            <PropertyTypeRoot />
          </AdminProtected>
        ),
        children: [
          {
            index: true,
            element: (
              <AdminProtected>
                <PropertyType />
              </AdminProtected>
            ),
          },
          {
            path: "add-propertyType",
            element: (
              <AdminProtected>
                <AddPropertyType />
              </AdminProtected>
            ),
          },
          {
            path: ":propertyTypeId",
            id: "propertyType-details",

            children: [
              {
                index: true,
                element: (
                  <AdminProtected>
                    <PropertyTypeItem />
                  </AdminProtected>
                ),
              },
              {
                path: "edit",
                element: (
                  <AdminProtected>
                    <EditPropertyType />
                  </AdminProtected>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "property-subtype",
        element: (
          <AdminProtected>
            <PropertySubTypeRoot />
          </AdminProtected>
        ),
        children: [
          {
            index: true,
            element: (
              <AdminProtected>
                <PropertySubType />
              </AdminProtected>
            ),
          },
          {
            path: "add-propertysubtype",
            element: (
              <AdminProtected>
                <AddPropertySubType />
              </AdminProtected>
            ),
          },
          {
            path: ":propertySubTypeId",
            id: "propertySubType-details",

            children: [
              {
                index: true,
                element: (
                  <AdminProtected>
                    <PropertySubTypeItem />
                  </AdminProtected>
                ),
              },
              {
                path: "edit",
                element: (
                  <AdminProtected>
                    <EditPropertySubType />
                  </AdminProtected>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "area",
        element: (
          <AdminProtected>
            <AreaRoot />
          </AdminProtected>
        ),
        children: [
          {
            index: true,
            element: (
              <AdminProtected>
                <Area />
              </AdminProtected>
            ),
          },
          {
            path: "add",
            element: (
              <AdminProtected>
                <AddArea />
              </AdminProtected>
            ),
          },
          {
            path: ":areaId",
            id: "area-details",

            children: [
              {
                path: ":stateId",
                element: (
                  <AdminProtected>
                    <AreaItem />,
                  </AdminProtected>
                ),
              },
              {
                path: ":stateId/edit",
                element: (
                  <AdminProtected>
                    <EditArea />
                  </AdminProtected>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "city",
        element: (
          <AdminProtected>
            <CityRoot />
          </AdminProtected>
        ),
        children: [
          {
            index: true,
            element: (
              <AdminProtected>
                <City />
              </AdminProtected>
            ),
          },
          {
            path: "add-city",
            element: (
              <AdminProtected>
                <AddCity />
              </AdminProtected>
            ),
          },
          {
            path: ":cityId",
            id: "city-details",

            children: [
              {
                index: true,
                element: (
                  <AdminProtected>
                    <CityItem />
                  </AdminProtected>
                ),
              },
              {
                path: "edit",
                element: (
                  <AdminProtected>
                    <EditCity />
                  </AdminProtected>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "lead",
        element: (
          <Protected>
            <LeadManagementRoot />
          </Protected>
        ),
        children: [
          {
            index: true,
            element: <LeadManagement />,
          },
          {
            path: "add-lead",
            element: <AddLeadManagement />,
          },
          {
            path: ":leadId",
            id: "lead-details",

            children: [
              {
                index: true,
                element: (
                  <Protected>
                    <LeadManagementItem />
                  </Protected>
                ),
              },
              {
                path: "edit",
                element: <EditLeadManagement />,
              },
            ],
          },
        ],
      },

      {
        path: "network",
        element: (
          <PartnerProtected>
            <NetworkManagementRoot />
          </PartnerProtected>
        ),
        children: [
          {
            index: true,
            element: (
              <PartnerProtected>
                <NetworkManagement />
              </PartnerProtected>
            ),
          },
          {
            path: "add-network",
            element: (
              <PartnerProtected>
                <AddNetworkManagement />
              </PartnerProtected>
            ),
          },
          {
            path: ":networkId",
            id: "network-details",

            children: [
              {
                index: true,
                element: (
                  <PartnerProtected>
                    <NetworkManagementItem />
                  </PartnerProtected>
                ),
              },
              {
                path: "edit",
                element: (
                  <PartnerProtected>
                    <EditNetworkManagement />
                  </PartnerProtected>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
]);

function App() {
  return (
    <div>
      <RouterProvider router={router} />
      <Footer />
    </div>
  );
}

export default App;

