import React, { useState } from "react";
import { Button, Card, Badge, ToggleButton, ToggleButtonGroup } from "react-bootstrap";

const PropertyDetail = ({ property, onBack }) => {
  const [status, setStatus] = useState(property.status.name === "Available");

  return (
    <div className="p-3 border rounded bg-white">
      <Button variant="primary" onClick={onBack} className="mb-3" size="m" style={{ fontSize: '1em', padding: '8px 15px', lineHeight: '1' }}>
        Back
      </Button>
      <Card className="p-3">
        <div className="d-flex align-items-stretch">
          {/* Image Section */}
          <Card.Img
            variant="top"
            src={`${property.assetUrl}${property.images[0].image_url}`}
            alt={property.title}
            style={{
              width: '600px', // Adjust width as needed
              height: '100%', // Ensures full height match
              objectFit: 'cover',
              borderRadius: '10px'
            }}
          />

          {/* Details Section */}
          <Card.Body className="ms-3 d-flex flex-column justify-content-between">
            <div>
              <h3>{property.title}</h3>

              {/* Status as Subscript */}
              <div className="mb-3" style={{ fontSize: '0.8em', color: 'gray', marginTop: '-10px' }}>
                <ToggleButtonGroup
                  type="checkbox"
                  value={status}
                  onChange={() => setStatus(!status)}
                  className="mb-2"
                >
                  <ToggleButton
                    value={true}
                    size="sm"
                    style={{ fontSize: '0.8em', padding: '5px 5px', lineHeight: '1', borderRadius: '12px', marginLeft: '45px', marginTop: '-8px' }}
                  >
                    Available
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>

              {/* Property Details */}
              <p className="text-primary fw-bold fs-5">₹{property.budget.toLocaleString()}</p>
              <p><Badge className="px-3 py-2 me-2">{property.purpose.name} </Badge> <Badge className="px-3 py-2 me-2">{property.type.name} </Badge> <Badge className="px-3 py-2 me-2"> {property.sub_type.name}</Badge></p>
              <p><Badge className="px-3 py-2 me-2">{property.state.name}</Badge><Badge className="px-3 py-2 me-2"> {property.city.name}</Badge></p>
              <p>{property.structure.bed_rooms} Beds  {property.structure.bath_rooms} Bath</p>
              <p>{property.state.name}</p>
              <p>{property.land_mark}</p>

              {/* Overview and Description */}
              <h5>Overview:</h5>
              <p>{property.description}</p>

              {/* Facilities */}
              <h5>Facilities:</h5>

              <ul class="row list-unstyled">
                {property.feature.swimming_pool && <li class="col-4 d-flex align-items-center"><span class="badge badge-success rounded-circle mr-2">✓</span> Swimming Pool</li>}
                {property.feature.gas_connection && <li class="col-4 d-flex align-items-center"><span class="badge badge-success rounded-circle mr-2">✓</span> Gas connection</li>}
                {property.feature.ro && <li class="col-4 d-flex align-items-center"><span class="badge badge-success rounded-circle mr-2">✓</span> RO</li>}
                {property.feature.club_house && <li class="col-4 d-flex align-items-center"><span class="badge badge-success rounded-circle mr-2">✓</span> Club House</li>}
                {property.feature.basketball_court && <li class="col-4 d-flex align-items-center"><span class="badge badge-success rounded-circle mr-2">✓</span> Basketball Court</li>}
                {property.feature.tennis_court && <li class="col-4 d-flex align-items-center"><span class="badge badge-success rounded-circle mr-2">✓</span> Tennis Court</li>}
                {property.feature.gym && <li class="col-4 d-flex align-items-center"><span class="badge badge-success rounded-circle mr-2">✓</span> Gym</li>}
                {property.feature.indoor_games && <li class="col-4 d-flex align-items-center"><span class="badge badge-success rounded-circle mr-2">✓</span> Indoor Games</li>}
                {property.feature.child_play_area && <li class="col-4 d-flex align-items-center"><span class="badge badge-success rounded-circle mr-2">✓</span> Children's Play Area</li>}
                {property.feature.hospital_near_by && <li class="col-4 d-flex align-items-center"><span class="badge badge-success rounded-circle mr-2">✓</span> Hospital Nearby</li>}
                {property.feature.mall_near_by && <li class="col-4 d-flex align-items-center"><span class="badge badge-success rounded-circle mr-2">✓</span> Mall Nearby</li>}
                {property.feature.market_near_by && <li class="col-4 d-flex align-items-center"><span class="badge badge-success rounded-circle mr-2">✓</span> Market Nearby</li>}
                {property.feature.schoold_near_by && <li class="col-4 d-flex align-items-center"><span class="badge badge-success rounded-circle mr-2">✓</span> School Nearby</li>}
              </ul>

            </div>
          </Card.Body>
        </div>
      </Card>


    </div>

  );
};

export default PropertyDetail;
