import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { InputGroup, FormControl, Button, Alert, Spinner, ListGroup, Image, Badge } from "react-bootstrap";
import { Search, Filter } from "lucide-react";
import { useSelector } from "react-redux";
import PropertyFilter from "./PropertyFilter";
import PropertyDetail from "./PropertyDetail";

const SearchComponent = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [allProperties, setAllProperties] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const suggestionRef = useRef(null);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const loggedInUser = useSelector((state) => state.auth.user);
  const token = loggedInUser?.token;

  useEffect(() => {
    const fetchProperties = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${baseUrl}/property/list/preferred/v1`,
          {
            type: "",
            purpose: "",
            state: "",
            city: "",
            area: "",
            price: "",
            keyboard_search: "",
            page_number: 1,
            page_size: 20,
            order_by: "ASC",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200 && response.data?.properties?.length > 0) {
          setAllProperties(response.data.properties);
          setFilteredProperties(response.data.properties);
        } else {
          setError("No properties found.");
          setAllProperties([]);
          setFilteredProperties([]);
        }
      } catch (err) {
        setError(err.response?.data?.message || "Failed to fetch data.");
      } finally {
        setLoading(false);
      }
    };

    fetchProperties();
  }, [baseUrl, token]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value.trim() === "") {
      setFilteredProperties(allProperties);
    } else {
      const filtered = allProperties.filter(
        (property) =>
          property.title.toLowerCase().includes(value.toLowerCase()) ||
          property.address.toLowerCase().includes(value.toLowerCase()) ||
          property.city?.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredProperties(filtered);
    }
  };

  const handleSelectProperty = (property) => {
    setSelectedProperty(property);
    setSearchTerm(property.title);
    setFilteredProperties([]);
  };

  const handleBackToList = () => {
    setSelectedProperty(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (suggestionRef.current && !suggestionRef.current.contains(event.target)) {
        setFilteredProperties([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="container py-4">
      {selectedProperty ? (
        <PropertyDetail property={selectedProperty} onBack={handleBackToList} />
      ) : (
        <>
          <InputGroup className="mb-3 position-relative">
            <FormControl
              placeholder="Search Properties"
              aria-label="Search"
              value={searchTerm}
              onChange={handleSearchChange}
              disabled={showFilter}
            />
            <Button variant="primary" disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : <Search />}
            </Button>
            <Button variant="secondary" onClick={() => setShowFilter(!showFilter)}>
              <Filter />
            </Button>
          </InputGroup>

          {showFilter ? (
            <PropertyFilter onClose={() => setShowFilter(false)} />
          ) : (
            <>
              {error && <Alert variant="danger">{error}</Alert>}

              {filteredProperties.length > 0 ? (
                <div className="row">
                  {filteredProperties.map((property) => (
                    <div key={property.id} className="col-md-4 mb-4">
                      <div className="card shadow-sm border rounded-1" onClick={() => handleSelectProperty(property)} style={{ cursor: "pointer" }}>
                        <img
                          src={`https://services.allhomedesire.com${property.images[0]?.image_url}`}
                          className="card-img-top rounded-top-1"
                          alt={property.title}
                          style={{ height: "180px", objectFit: "cover" }}
                        />
                        <div className="card-body">
                          <h5 className="card-title">{property.title || "N/A"}</h5>
                          <p className="text-primary fw-bold fs-5"> ₹{property.budget?.toLocaleString()}</p>
                          <p> {property.area?.name}, {property.city?.name}, {property.state?.name}</p>
                          <p className="card-text text-truncate">{property.description}</p>
                          <div className="d-flex justify-content-between">
                            <Badge bg="warning" text="dark" className="px-3 py-2">
                              {property.purpose?.name}
                            </Badge>
                            <Badge bg="primary" className="px-3 py-2">
                              {property.type?.name}
                            </Badge>
                            <Badge bg="success" className="px-3 py-2">
                              {property.sub_type?.name}
                            </Badge>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default SearchComponent;


     

     