import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Form, Button, InputGroup} from 'react-bootstrap';
import { Eye, EyeOff, Lock } from 'lucide-react';
import forgotPasswordImg from "../assets/images/ResetPassword.png"

export default function ResetPasswordScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email || ''; // Get email passed from OTP screen

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const handleResetPassword = async () => {
    if (password === confirmPassword && password.length >= 6) {
      try {
        const response = await fetch('https://services.allhomedesire.com/all/noAuthChangePassword', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: email,
            password: password,
          }),
        });

        const data = await response.json();

        if (response.ok) {
          alert('Password updated successfully!');
          navigate('/login');
        } else {
          alert(data.message || 'Failed to update password.');
        }
      } catch (error) {
        alert('Error updating password: ' + error.message);
      }
    } else {
      alert('Passwords do not match or are too short!');
    }
  };

  return (
    <Container className="mt-5"style={{ maxWidth: "400px",maxHeight:"800px" }}>
         <div className="text-center mb-4">
        <img 
          src={forgotPasswordImg} 
          alt="Forgot Password" 
          style={{ width: "100%", maxWidth: "200px"}} 
        />
      </div>
      <h2 >Reset Password</h2>
      <p className="text-left" style={{ color: "grey", fontSize: "12px" }}>Please enter a new password for {email}</p>
      
      <Form className="d-flex flex-column align-items-left">
        {/* New Password Input */}
        <Form.Group className="mb-3">
          <Form.Label>New Password</Form.Label>
          <InputGroup>
            <InputGroup.Text>
              <Lock size={16} />
            </InputGroup.Text>  
            <Form.Control
              type={passwordVisible ? 'text' : 'password'}
              placeholder="Enter new password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button variant="primary" onClick={() => setPasswordVisible(!passwordVisible)}>
              {passwordVisible ? <EyeOff size={16} /> : <Eye size={16} />}
            </Button>
          </InputGroup>
        </Form.Group>

        {/* Confirm Password Input */}
        <Form.Group className="mb-3">
          <Form.Label>Confirm Password</Form.Label>
          <InputGroup>
            <InputGroup.Text>
              <Lock size={16} />
            </InputGroup.Text>
            <Form.Control
              type={confirmPasswordVisible ? 'text' : 'password'}
              placeholder="Confirm new password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <Button variant="primary" onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}>
              {confirmPasswordVisible ? <EyeOff size={16} /> : <Eye size={16} />}
            </Button>
          </InputGroup>
        </Form.Group>

        <Button className="btn btn-primary w-100 mb-3"  variant="primary" onClick={handleResetPassword}>
          Submit
        </Button>
      </Form>
    </Container>
  );
}
